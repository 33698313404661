import { useCards } from 'hooks/useCards'
import { useForm } from 'react-hook-form'
import { _validators } from '@redsales/ds/utils'
import { navigate } from 'gatsby'
import { useEffect, useState } from 'react'
import storage from 'utils/Storage'
import makeTrackings from 'utils/make/trackings'
import { cardsFormId } from 'utils/make/constants'

const useCardAddress = () => {
  const { setAddress } = useCards()

  const [isLoadingCep, setIsLoadingCep] = useState(false)

  const { control, handleSubmit, formState, setError, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      zipCode: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
    },
  })

  useEffect(() => {
    const { address: storagedAddress } =
      storage.session.getItem('selected_card_data') || {}

    if (storagedAddress) {
      setValue('zipCode', storagedAddress.zipCode)
      setValue('street', storagedAddress.street)
      setValue('number', storagedAddress.number)
      setValue('complement', storagedAddress.complement)
      setValue('neighborhood', storagedAddress.neighborhood)
      setValue('city', storagedAddress.city)
      setValue('state', storagedAddress.state)
    }
  }, [])

  const validateFields = (data) => {
    let isValid = true

    if (!_validators.cep(data.zipCode) || !data.zipCode) {
      setError('zipCode', {
        type: 'manual',
        message: 'CEP inválido',
      })

      isValid = false
    }

    if (data.street.length <= 3) {
      setError('street', {
        type: 'manual',
        message: 'Rua inválida',
      })

      isValid = false
    }

    if (!data.number) {
      setError('number', {
        type: 'manual',
        message: 'Número é obrigatório',
      })

      isValid = false
    }

    if (data.neighborhood.length <= 3) {
      setError('neighborhood', {
        type: 'manual',
        message: 'Bairro inválido',
      })

      isValid = false
    }

    if (data.city.length <= 3) {
      setError('city', {
        type: 'manual',
        message: 'Cidade inválida',
      })

      isValid = false
    }

    if (data.state.length !== 2) {
      setError('state', {
        type: 'manual',
        message: 'Estado inválido',
      })

      isValid = false
    }

    return isValid
  }

  const onSubmit = (data) => {
    const isValid = validateFields(data)

    if (!isValid) {
      return
    }

    makeTrackings.formContinued({
      formId: cardsFormId,
      formName: 'cards',
      stepId: '6e332c68-d253-4a7f-a8f1-9d280bc4d600',
      stepName: 'cards-address',
      stepNumber: 3,
    })

    setAddress(data)

    navigate('/cartoes/resumo')
  }

  const getCep = (e) => {
    const { value } = e.target
    const cep = value.replace(/\D/g, '')

    if (cep.length !== 8) return

    setIsLoadingCep(true)

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setValue('street', data.logradouro)
        setValue('neighborhood', data.bairro)
        setValue('city', data.localidade)
        setValue('state', data.uf)
      })
      .finally(() => {
        setIsLoadingCep(false)
        document.querySelector('[name="street"]').focus()
      })
  }

  return {
    onSubmit: handleSubmit(onSubmit),
    control,
    formState,
    setValue,
    getCep,
    isLoadingCep,
  }
}

export default useCardAddress
