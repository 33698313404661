import AppLayout from 'layouts/AppLayout'
import { Fields } from '@redsales/ds'
import Button from 'components/Button'
import useCardAddress from './useCardAddress'

import './CardAddress.scss'
import makeTrackings from 'utils/make/trackings'
import { cardsFormId } from 'utils/make/constants'

const CardAddress = () => {
  const { control, formState, onSubmit, isLoadingCep, getCep } =
    useCardAddress()

  return (
    <AppLayout
      contentHeaderInfos={{
        title: 'Só mais algumas informações.',
        description:
          'Precisamos saber qual endereço que iremos entregar seu cartão.',
        onTrack: () => {
          makeTrackings.formBacked({
            formId: cardsFormId,
            formName: 'cards',
            stepId: '555474c0-127d-4efb-bf26-9d181e60d9f4',
            stepName: 'cards-address',
            stepNumber: 3,
          })
        },
      }}
      contentClassName="card-address"
    >
      <form onSubmit={onSubmit} className="card-address__form">
        <div className="form-container address-form">
          <Fields.Input.Text
            control={control}
            name="zipCode"
            label="CEP"
            mask="99.999-999"
            maskChar=""
            onBlur={getCep}
            disabled={isLoadingCep}
          />

          <Fields.Input.Text
            control={control}
            name="street"
            label="rua"
            disabled={isLoadingCep}
          />

          <Fields.Input.Text
            control={control}
            name="number"
            label="número"
            disabled={isLoadingCep}
          />

          <Fields.Input.Text
            control={control}
            name="complement"
            label="complemento"
            disabled={isLoadingCep}
          />

          <Fields.Input.Text
            control={control}
            name="neighborhood"
            label="bairro"
            disabled={isLoadingCep}
          />

          <Fields.Input.Text
            control={control}
            name="city"
            label="cidade"
            disabled={isLoadingCep}
          />

          <Fields.Input.Text
            control={control}
            name="state"
            label="UF"
            mask="aa"
            disabled={isLoadingCep}
          />
        </div>

        <Button
          className="card-address__button"
          type="submit"
          disabled={
            !formState?.isValid ||
            formState?.isSubmitting ||
            isLoadingCep ||
            Object.keys(formState?.errors || {}).length > 0
          }
        >
          Próximo
        </Button>
      </form>
    </AppLayout>
  )
}

export default CardAddress
